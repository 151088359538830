import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { formatError } from '../contexts/ResponseErrorFormatter';
import completionApi from '../api/completions';
import moment from 'moment';
import { get, has } from 'lodash-es';
import { COMPLETION_DP_ACTION, UPLOAD_STATES } from '../Constants';
import { calculateChecksum } from '../components/Common/Utils';

/**
 * @param {number} projectID is the id of the project to which the completion belongs to
 * @param {boolean} isReviewer an indicator to know wether the loaded completion is for processing or review
 * @param {string} name the file name
 * @param {number} size file size
 * @param {string} tagId Unique identifier for the component (ex: name)
 * @param {string} fileId unique identifier for uploaded file
 * @param {string} previewURL URL for file inside bucket to be displayed
 * @param {string} status status for the file beinng uploaded (default value NotStarted)
 * @param {boolean} hasResult an indecator to know whether files has been files to be submitted or not
 * @param {string} error
 */

function getIsUploadMinValid({ minFilesByTagId, completionEntry }) {
    const defaultCount = Object.keys(minFilesByTagId).reduce((acc, tagId) => {
        acc[tagId] = 0
        return acc
    }, {})
    const countFilesByTagId = Object.values(completionEntry?.files ?? [])?.reduce((accum, fileInfo) => {
        if (has(accum, fileInfo.tagId)) {
            accum[fileInfo.tagId] += 1;
        }
        return accum
    }, defaultCount)

    const isUploadMinValid = Object.entries(countFilesByTagId)?.reduce((accum, [tagId, count]) => {
        return accum && (count >= get(minFilesByTagId, tagId))
    }, true)

    return isUploadMinValid;
}

export const performUpload = createAsyncThunk(
	'upload/performUpload',
	async (
		{ completionID, fileFormData, fileId },
		{ getState, rejectWithValue, dispatch },
	) => {
		try {
			await dispatch(
				getUploadSessionUrl({
					fileId: fileId,
					completionID: completionID,
				}),
			).unwrap();
			await dispatch(
				uploadFileToBucket({
					fileFormData,
					fileId: fileId,
					completionID: completionID,
				}),
			).unwrap();
			await dispatch(
				streamUploadFilesStatus({
					fileId: fileId,
					completionID: completionID,
				}),
			).unwrap();
		} catch (e) {
			return rejectWithValue(e);
		}
		const state = getState().upload;
		return state.toUploadCompletionFiles[completionID].files[fileId];
	},
);
export const getUploadSessionUrl = createAsyncThunk(
	'upload/getUploadSessionUrl',
	async ({ completionID, fileId }, { getState, rejectWithValue }) => {
		const state = getState().upload;
		const completionEntry = state.toUploadCompletionFiles[completionID];
		const checksum = await calculateChecksum(
			completionEntry.files[fileId].originFileObj,
		);

		const fileInfo = {
			ext: completionEntry.files[fileId].name.split('.').pop(),
			size: completionEntry.files[fileId].size,
			tagId: completionEntry.files[fileId].tagId,
			contentType: completionEntry.files[fileId].type,
			checksum: checksum,
		};

		try {
			const response = await completionApi.initiateUploadSession(
				parseInt(completionID),
				fileInfo,
			);
			return response.data;
		} catch (e) {
			const formattedError = formatError(
				e,
				`Failed To Get Upload URL For Completion ${completionID}!`,
			);
			return rejectWithValue(formattedError);
		}
	},
);
export const uploadFileToBucket = createAsyncThunk(
	'upload/uploadFileToBucket',
	async ({ fileFormData, fileId, completionID }, { getState, rejectWithValue, dispatch }) => {
		const state = getState().upload;
		try {
			const completionEntry = state.toUploadCompletionFiles[completionID];

			// There is no need to try uploading the file if there is no result.
			if (!completionEntry.hasResult) return;

			const { error, uploadUrl } = completionEntry.files[fileId];

			// Throwing the found error instead of continuing with the call.
			if (error) return rejectWithValue(error);

			if (!uploadUrl) {
				return rejectWithValue({
					title: 'Upload URL is not available!',
					detail: 'Failed to get the upload URL for the file! Aborting the upload process...',
				});
			}
			// await completionApi.uploadFileToBucket(parseInt(completionID), fileFormData);

			await completionApi.uploadRecordedAudioToBucket(
				uploadUrl,
				fileFormData.get('file'),
				(progressEvent) => {
					const progress = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total,
					);
					dispatch(
						setUploadFileProgress({
							completionID,
							progressValue: progress,
							status: "Uploading",
							refID: fileId,
						}),
					);
				},
			);
		} catch (err) {
			const formattedError = formatError(
				err,
				`Failed To Upload The File To Bucket For Completion ${state.activeCompletionID}!`,
			);
			formattedError.error = err;
			return rejectWithValue(formattedError);
		}
	},
);

export const deleteFileFromBucket = createAsyncThunk(
    'upload/deleteFileFromBucket',
    async (fileId, { getState, rejectWithValue }) => {
        const state = getState().upload;
        const completionID = state.activeCompletionID;
        try {
            const completionEntry = state.toUploadCompletionFiles[completionID];
            const { error } = completionEntry.files[fileId];

            // Throwing the found error instead of continuing with the call.
            if (error) return rejectWithValue(error);

            await completionApi.deleteFileInBucket(parseInt(fileId));

        } catch (e) {
            const formattedError = formatError(e, `Failed To Delete The File From Bucket For Completion ${completionID}!`);
            return rejectWithValue(formattedError);
        }
    },
);

export const getFileURL = createAsyncThunk(
    'upload/getFileURL',
    async (fileId, { getState, rejectWithValue }) => {
        const state = getState().upload;
        try {
            const completionEntry = state.toUploadCompletionFiles[state.activeCompletionID];
            const { error } = completionEntry.files[fileId];

            // Throwing the found error instead of continuing with the call.
            if (error) return rejectWithValue(error);

            const uploadedFileURL = await completionApi.getFileURL(parseInt(fileId));
            return uploadedFileURL.data;

        } catch (e) {
            const formattedError = formatError(e, `Failed To preview file!`);
            return rejectWithValue(formattedError);
        }
    }
);

export const getAcceptedExtensions = createAsyncThunk(
    'upload/getAcceptedExtensions',
    async (_, { getState, rejectWithValue }) => {
        try {
            const acceptedExtensions = await completionApi.getAcceptedExtensions();
            return acceptedExtensions.data;
        } catch (e) {
            const formattedError = formatError(e, `Failed To fetch extensions!`);
            return rejectWithValue(formattedError);
        }
    }
);

// Submits the rest of the results of the LSF components.
export const submitUploadedFilesCompletion = createAsyncThunk(
    'upload/submitCompletionResult',
    async (completionID, { getState, rejectWithValue }) => {
        try {
            const state = getState().upload;
            const { result, error } = state.toUploadCompletionFiles[completionID];


            // Throwing the found error instead of continuing with the call.
            if (error) return rejectWithValue(error);

            // Sending the completion result.
            await completionApi.dpSendCompletionResult(completionID, { action: COMPLETION_DP_ACTION.Submit, result });
        } catch (e) {
            const formattedError = formatError(e, `Failed To Submit Result Of Completion: ${completionID}`);
            return rejectWithValue(formattedError);
        }
    }
)


export const streamUploadFilesStatus = createAsyncThunk(
	'upload/streamUploadFileStatus',
	async ({ completionID, fileId }, { getState, rejectWithValue, dispatch }) => {
		const state = getState().upload;
		const completionEntry = state.toUploadCompletionFiles[completionID];
		const { error, dbFileId } = completionEntry.files[fileId];

		return new Promise((resolve, reject) => {
			let mappedStatus;

			// Throwing the found error instead of continuing with the call.
			if (error) return rejectWithValue(error);
			const handleProgress = (data, eventSource) => {
				const { status, errors, valid, failureReason } = data;
				switch (status) {
					case 'VALIDATION_SUCCESS':
						mappedStatus = UPLOAD_STATES.Finished;
						break;
					case 'VALIDATION_ERROR':
						mappedStatus = UPLOAD_STATES.Invalid;
						break;
					case 'FAILURE':
						mappedStatus = UPLOAD_STATES.Failed;
						break;
					default:
						mappedStatus = null;
						break;
				}

				if (mappedStatus === UPLOAD_STATES.Finished) {
					const result = {
						completionID,
						status: mappedStatus,
						errors: errors,
						failureReason: failureReason,
						refID: fileId,
					};
					dispatch(setUploadFileProgress(result));
					eventSource.close();
					resolve(result);
				}

				if (
					mappedStatus === UPLOAD_STATES.Invalid ||
					mappedStatus === UPLOAD_STATES.Failed
				) {
					dispatch(
						setUploadFileProgress({
							completionID,
							status: mappedStatus,
							errors: errors,
							failureReason: failureReason,
							refID: fileId,
						}),
					);
					eventSource.close();
					reject(failureReason || 'Failed to upload file');
				}
			};

			const handleError = (error) => {
				const formattedError = formatError('EventSource failed:', error);
				dispatch(
					setUploadFileProgress({
						completionID,
						status: mappedStatus,
						errors: [],
						failureReason: 'Failed to check file upload progress',
						refID: fileId,
					}),
				);
				reject(formattedError);
			};

			completionApi.streamUploadFileStatus(
				dbFileId,
				handleProgress,
				handleError,
			);
		});
	},
);



const initialState = {
    activeCompletionID: null,
    isReviewer: false,
    projectID: undefined,
    hasResult: false,
    toUploadCompletionFiles: {},
    acceptedExtensions: [],
    acceptedExtensionsError: null
}

const EMPTY_FILE_ENTRY = {
    tagId: '',
    fileId: '',
    size: 0,
    name: '',
    previewURL: '',
    status: "NotStarted",
    error: null,
}

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        loadCompletion: (state, action) => {

            const prevCompletionEntries = Object.entries(state.toUploadCompletionFiles)
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

					if (!action.payload) {
							return
					}

					// The payload is the completion ID in this case.
					const { completion: payloadCompletion, projectID, isReviewer } = action.payload;
					const { files: completionFiles, id: completionID } = payloadCompletion;
					const hasUploadTag = payloadCompletion?.config && payloadCompletion?.config?.includes('<Upload');


					if (!hasUploadTag) {
								state.activeCompletionID = null;
						state.minFilesByTagId = {};
						state.isUploadMinValid = false;
						state.hasUploadTag = false;
						state.toUploadCompletionFiles = { ...prevCompletionEntries };
						return;
					}

					state.hasUploadTag = true;

					// Creating or updating the completion entry.
					// We reset the status because the user may select to skip when upload fails in the first
					// and this should remove the completion entry in this case from the list. Note: Skip is
					// handled by the completion context and not the redux store.
					let completionEntry = (completionID in state.toUploadCompletionFiles) ? {
							...state.toUploadCompletionFiles[completionID],
							status: "NotStarted",
					} : { ...EMPTY_FILE_ENTRY }




					let fileEntries = {
						files: {
							...completionEntry.files,
						}
					}
					fileEntries.minFiles = completionEntry.minFiles;



					if (completionFiles && !state.hasResult) {
							if (completionFiles.length > 0) {
									completionFiles.forEach(fileEntry => {
											if (Object.keys(fileEntry).length > 0) {

													const hasError = fileEntry.errors && fileEntry.errors.length > 0;
													completionEntry = {
															...completionEntry,
															uid: fileEntry.id,
															fileId: fileEntry.id,
															name: fileEntry.name,
															tagId: fileEntry.tagId,
															status: hasError ? UPLOAD_STATES.Invalid : UPLOAD_STATES.Finished,
															type: fileEntry.contentType,
															order: fileEntry.order,
															errors: fileEntry.errors,
															uploadedAt: moment(fileEntry.uploadedAt).format('D MMM, YYYY'),
															valid: fileEntry.valid
													}
													fileEntries = {
															...fileEntries,
															hasResult: true,
															files: {
																	...fileEntries.files,
																	[fileEntry.id]: { ...completionEntry }
															}
													}
											}
									});
							}
					}

					// Updating the activeCompletionID with the payload, even if it is equal to null.
						state.activeCompletionID = completionID;
					state.minFilesByTagId = {}
					// Creating or updating the entry for the current completion.
					state.toUploadCompletionFiles = {
							...prevCompletionEntries,
							[completionID]: {
									isReviewer,
									projectID,
									...fileEntries
							}
					};
        },

        setAttachedFileInfo: (state, action) => {
            // Making sure there is an active completion ID selected. activeCompletionID is null in the case when the project manager
            // upload a file from the configuration view.
            if (state.activeCompletionID === null || !(state.activeCompletionID in state.toUploadCompletionFiles))
                return;

            // Renaming the payload
            let filesEntries = {};
            const { fileList, name: tagId } = action.payload;
            let completionEntry = {}

            //Normalizing data
            fileList.forEach(fileEntry => {

							if (Object.keys(fileEntry).length > 0) {
                    Object.assign(filesEntries, { [fileEntry.uid]: { ...fileEntry, tagId } })
                }
            });

            // Updating the current active completion entry with the file information which
            // is uploaded by the upload component.
            // state.toUploadCompletionFiles[state.activeCompletionID].files
            completionEntry = {
                ...state.toUploadCompletionFiles[state.activeCompletionID],
                hasResult: true,
                files: { ...state.toUploadCompletionFiles[state.activeCompletionID].files, ...filesEntries }
            };

            const { minFilesByTagId } = state;


            state.isUploadMinValid = getIsUploadMinValid({ minFilesByTagId, completionEntry })

            // Updating the completion entry in the state.
            state.toUploadCompletionFiles = {
                ...state.toUploadCompletionFiles,
                [state.activeCompletionID]: completionEntry,
            };

        },

        setCompletionResult: (state, action) => {

            if (state.activeCompletionID === null)
                throw new Error('activeCompletionID is not set!!!');

            // Renaming the payload
            const completionSerializedResult = action.payload;

            // Updating the result for the completion entry with the serialized completion result.
            const completionEntry = {
                ...state.toUploadCompletionFiles[state.activeCompletionID],
                result: completionSerializedResult,
            };

            // Updating the completion entry in the state.
            state.toUploadCompletionFiles = {
                ...state.toUploadCompletionFiles,
                [state.activeCompletionID]: completionEntry,
            };

        },
        removeFileEntry: (state, action) => {
            // Updating the status of the completion entry.
            const fileId = action.payload;
            const completionEntry = state.toUploadCompletionFiles[state.activeCompletionID];

            if (completionEntry.files.hasOwnProperty(fileId)) {
                delete completionEntry.files[fileId]
            }

            if (Object.keys(completionEntry.files).length === 0)
                completionEntry.hasResult = false;

            state.isUploadMinValid = getIsUploadMinValid({
                completionEntry,
                minFilesByTagId: state.minFilesByTagId,
            });
        },
        setMinFiles: (state, action) => {
            // Updating the status of the completion entry.
            const { minFileCount, tagId } = action.payload;

            let completionEntry = state.toUploadCompletionFiles[state.activeCompletionID];

            completionEntry = {
                ...completionEntry,
            }

            state.minFilesByTagId = {
                ...(state?.minFilesByTagId ?? {}),
                [tagId]: parseInt(minFileCount),
            }

            state.isUploadMinValid = getIsUploadMinValid({
                completionEntry,
                minFilesByTagId: state.minFilesByTagId,
            });

            state.toUploadCompletionFiles = {
                ...state.toUploadCompletionFiles,
                [state.activeCompletionID]: completionEntry,
            };
        },
			setUploadFileProgress: (state, action) => {
				const { completionID, progressValue, status, refID, errors} = action.payload;
				if (!state.toUploadCompletionFiles[completionID]?.files[refID]) {
					return;
				}
				state.toUploadCompletionFiles[completionID].files[refID].progress = progressValue || 0;
				state.toUploadCompletionFiles[completionID].files[refID].status = status;
				state.toUploadCompletionFiles[completionID].files[refID].errors = errors || null;
				// Updating the completion entry.
			}
    },

    extraReducers: {
				[getUploadSessionUrl.pending]: (state, action) => {
					const {fileId, completionID} = action.meta.arg;
					const completionEntry = state.toUploadCompletionFiles[completionID];

					completionEntry.files[fileId].status = UPLOAD_STATES.Preparing;
					completionEntry.files[fileId].error = null;
					completionEntry.files[fileId].uploadUrl = null;
					completionEntry.files[fileId].filename = null;
					completionEntry.files[fileId].processId = null;
					completionEntry.files[fileId].progress = null;

				},
				[getUploadSessionUrl.fulfilled]: (state, action) => {
					const {fileId, completionID} = action.meta.arg;
					const completionEntry = state.toUploadCompletionFiles[completionID];
					const { processId, filename, url, fileId: dbFileId} = action.payload;

						completionEntry.files[fileId].uploadUrl = url;
						completionEntry.files[fileId].filename = filename;
						completionEntry.files[fileId].processId = processId;
						completionEntry.files[fileId].dbFileId = dbFileId;

				},
				[getUploadSessionUrl.rejected]: (state, action) => {
						const {fileId, completionID} = action.meta.arg;
						const completionEntry = state.toUploadCompletionFiles[completionID];
						const error = action.payload;
						console.log('error', error);
						if (error.status === 409) {
							completionEntry.files[fileId].status = UPLOAD_STATES.Invalid;
							completionEntry.files[fileId].errors = error?.details;
						} else {
							completionEntry.files[fileId].status = UPLOAD_STATES.Failed;
							completionEntry.files[fileId].error = error;
						}
				},
        [uploadFileToBucket.pending]: (state, action) => {
            // Retrieving the argument sent to the thunk.
            //{fileFormData: FormData, tagId: 'test_name', fileId: 'rc-upload-1663862926404-2'}
            const { fileId, completionID } = action.meta.arg;

            // Updating the status of the completion entry.

            const completionEntry = state.toUploadCompletionFiles[completionID];

            completionEntry.files[fileId].status = 'Uploading';
						completionEntry.files[fileId].progress = 0;
						completionEntry.files[fileId].error = null;

        },
        [uploadFileToBucket.fulfilled]: (state, action) => {
            // Retrieving the argument sent to the thunk.
            const { fileId, completionID } = action.meta.arg;
            //const completionID = state.activeCompletionID;
            const completionEntry = state.toUploadCompletionFiles[completionID];

            completionEntry.files[fileId].status = 'Finished';

        },
        [uploadFileToBucket.rejected]: (state, action) => {
            // Retrieving the argument sent to the thunk.
            const { fileId, completionID } = action.meta.arg;
            const error = action.payload;

            // Updating the status of the completion entry.
            const completionEntry = state.toUploadCompletionFiles[completionID];
            completionEntry.files[fileId].status = 'Failed';
            completionEntry.files[fileId].error = error;

        },

        [deleteFileFromBucket.fulfilled]: (state, action) => {
            // Retrieving the argument sent to the thunk.
            const fileId = action.meta.arg;

            // Updating the status of the completion entry.
            const completionEntry = state.toUploadCompletionFiles[state.activeCompletionID];

            if (completionEntry.files.hasOwnProperty(fileId)) {
                delete completionEntry.files[fileId]
            }

            if (Object.keys(completionEntry.files).length === 0)
                completionEntry.hasResult = false;

            state.isUploadMinValid = getIsUploadMinValid({
                completionEntry,
                minFilesByTagId: state.minFilesByTagId,
            });
        },
        [deleteFileFromBucket.rejected]: (state, action) => {
          // Retrieving the argument sent to the thunk.
          const fileId = action.meta.arg;
          const completionID = state.activeCompletionID;

          const error = action.payload;

					const completionEntry = state.toUploadCompletionFiles[completionID];

					completionEntry.files[fileId] = {
						...completionEntry.files[fileId],
						error: error.title
					};
        },

        [getFileURL.fulfilled]: (state, action) => {
            // Retrieving the argument sent to the thunk.
            const fileId = action.meta.arg;
            state.toUploadCompletionFiles[state.activeCompletionID].files[fileId].previewURL = action.payload
        },
        [getFileURL.rejected]: (state, action) => {
          // Retrieving the argument sent to the thunk.
          const fileId = action.meta.arg;
          const completionID = state.activeCompletionID;
          const error = action.payload;

					const completionEntry = state.toUploadCompletionFiles[completionID];

					completionEntry.files[fileId] = {
						...completionEntry.files[fileId],
						error
					};

        },

        [getAcceptedExtensions.fulfilled]: (state, action) => {
            state.acceptedExtensions = action.payload
        },
        [getAcceptedExtensions.rejected]: (state, action) => {
            const error = action.payload;
            state.acceptedExtensionsError = error;
        },
			[streamUploadFilesStatus.pending]: (state, action) => {
				const {fileId, completionID} = action.meta.arg;
				const completionEntry = state.toUploadCompletionFiles[completionID];

				completionEntry.files[fileId].status = UPLOAD_STATES.Validating;
				completionEntry.files[fileId].error = null;
				completionEntry.files[fileId].progress = null;
			},
			[streamUploadFilesStatus.rejected]: (state, action) => {
				const { fileId, completionID } = action.meta.arg;
				const error = action.payload;

				const completionEntry = state.toUploadCompletionFiles[completionID];
				completionEntry.files[fileId].status = UPLOAD_STATES.Failed;
				completionEntry.files[fileId].error = error;
			},

    }
})

// Action creators are generated for each case reducer function
export const {
    loadCompletion: loadUploadTagCompletion,
    resetHasUploadTag,
    setAttachedFileInfo,
    setCompletionResult: setUploadTagCompletionResult,
		setUploadFileProgress ,
    removeFileEntry,
    setMinFiles
} = uploadSlice.actions

export default uploadSlice.reducer
