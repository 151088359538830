import { snakeCase } from 'lodash-es';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import CryptoJS from 'crypto-js';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export const sortCompare = (x, y) => {
    if (typeof x === 'string' && typeof y === 'string')
        return x.localeCompare(y)
    else if (typeof x === 'number' && typeof y === 'number')
        return x - y
    else if (!y)
        return -1;
    else if (!x)
        return 1;
    else
        return 0
}

/**
 * From date to DD-MM-YYYY
 *
 * @deprecated  use {@link fromatDateTime}
 * @param {string|Date} date
 * @param {*} fallback - return is date is invalid
 *
 * @returns string
 */
export const formatDate = (date, fallback='Not Available') => {
    const d = dayjs(date)
    if (date && !isNaN(d)) {
        return dayjs(date).format('DD-MM-YYYY')
    }
    return fallback;
}

/**
 * format `date` using `format`
 *
 * @param {string|Date} date
 * @param {string?} format - format to use [default MMM DD, YYYY hh:mm A]
 *
 * @returns string
 */
export const formatDateTime = (date, format='MMM DD, YYYY hh:mm A') => {
    return dayjs(date).format(format);
}

export const getFormattedUserLabel = (ct) => {

    const firstName = (ct.firstName && ct.firstName.length > 0) ? ct.firstName : ''
    const lastName = (ct.lastName && ct.lastName.length > 0) ? ct.lastName : ''
    const fullName = `${firstName} ${lastName}`

    return fullName.length > 0 ?
        `${fullName} [${ct?.email}]` :
        `${ct?.email}`
}

const toDate = (dateTimeString) => new Date((new Date(dateTimeString)).toDateString())
const getToday = () => new Date((new Date()).toDateString())
const milliSecondsInDay = 1000 * 3600 * 24;
export const calcElapsedTime = (project) => {

    if (project.status === 2)//Cancelled
        return '-';

    let today = getToday()
    let startDate = toDate(project.startedOn);
    let etaDate = toDate(project.eta);
    let completionDate = project.completedOn ? toDate(project.completedOn) : null;

    if (project.startedOn === null ||
        project.eta === null ||
        isNaN(etaDate) ||
        isNaN(startDate) ||
        etaDate < startDate ||
        today < startDate)
        return '-';

    let timeSpent = completionDate ? completionDate.getTime() - startDate.getTime() : today.getTime() - startDate.getTime()
    let daysSpent = (timeSpent / milliSecondsInDay) + 1
    let daysPlanned = ((etaDate.getTime() - startDate.getTime()) / milliSecondsInDay) + 1

    return (daysSpent / daysPlanned * 100).toFixed(1) + '%';
}


export const validateEmail = (email) => {
    const validEmail = new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,15}/g).test(email);
    return validEmail;
}

const HOUR_SECONDS = 3600;
const MINUTE_SECONDS = 60;

export const convertSecondsToTimeFormat = (secs) => {
    const numHandleTimeInSec = Number(secs);
    if (isNaN(numHandleTimeInSec))
        return 'Invalid';

    const hours = Math.trunc(numHandleTimeInSec / HOUR_SECONDS);
    const minutes = Math.trunc((numHandleTimeInSec % HOUR_SECONDS) / MINUTE_SECONDS);
    const seconds = numHandleTimeInSec % MINUTE_SECONDS;

    const locale = 'en-US';
    const localeParams = { minimumIntegerDigits: 2, useGrouping: false };
    return `${hours.toLocaleString(locale, localeParams)}:${minutes.toLocaleString(locale, localeParams)}:${seconds.toLocaleString(locale, localeParams)}`;
}

export const capitalizeFirstChar = (string) => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
};


export const downloadFile = ({ file, projectId, projectName, fileType, fileExtension }) => {
    const binaryData = [];
    binaryData.push(file);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: fileType }))
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${projectId}_${snakeCase(projectName)}_Results.${fileExtension}`);
    link.click();
};

export const formatTimeToNow = (date, option) => {
    try {
        const fromDate = dayjs(date);
        if(date && fromDate.isValid()){
            return dayjs().to(fromDate)
        }
        throw new Error("Invalid Date")
    } catch (error) {
        if (option?.fallback !== undefined || option?.fallback !== false) {
            return option?.fallback;
        }
        throw error;
    }
}

	export const calculateChecksum = (file) =>
		new Promise((resolve) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				const arrayBuffer = event.target.result;

				// Convert ArrayBuffer to WordArray for CryptoJS
      	const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
				const md5 = CryptoJS.MD5(wordArray).toString();
				resolve(md5);
			};
			reader.readAsArrayBuffer(file);
		});
